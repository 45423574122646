import React, { createContext, useState, useContext, useEffect } from 'react';
import { collection, collectionGroup, doc, getDoc, getDocs, query, where, orderBy, limit } from 'firebase/firestore';
import { db } from './../firebase'; // Assume Firestore instance is imported from here
import { useUser } from './UserContext.js';

const SecondHeaderContext = createContext();

export const useSecondHeader = () => {
    return useContext(SecondHeaderContext);
};

export const SecondHeaderProvider = ({ children }) => {
    const [availableLocations, setAvailableLocations] = useState([]);
    const [currentLocation, setCurrentLocation] = useState(undefined);
    const [currentUnit, setCurrentUnit] = useState(undefined);
    const [firstUse, setFirstUse] = useState(undefined);
    const [latestUseString, setLatestUseString] = useState('');
    const { userData } = useUser();

    const resetCurrentUnit = () => {
        setCurrentUnit(undefined);
    };

    const resetCurrentLocation = () => {
        setCurrentLocation(undefined);
    };

    const getUnitsData = async (unit_ids) => {
        //console.log("Firebase - ")
        if (unit_ids.length === 0) return [];

        const q = query(collection(db, 'units'), where('__name__', 'in', unit_ids));
        console.log("Firebase call")
        const querySnapshot = await getDocs(q);

        return querySnapshot.docs.map(doc => doc.data());
    };

    const getUnitData = async (unit_id) => {
        //console.log("Firebase - ")
        //console.log("2 - Fetching unit data...");
        if (!unit_id) {
            setCurrentUnit(undefined);
            return undefined;
        }

        //console.log("2 - Current unit:", currentUnit);
        if (currentUnit && currentUnit.unit_id === unit_id) {
            return currentUnit;
        }
        //console.log("2 - Fetching unit data from Firestore...");
        const docRef = doc(db, 'units', unit_id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            //console.log("3 - Unit data fetched from Firestore:", docSnap.data());
            const unit = docSnap.data();

            // Handle eval_categories reordering
            if (unit.eval_categories.includes("UNDEFINED")) {
                unit.eval_categories = unit.eval_categories.filter(cat => cat !== "UNDEFINED").concat("UNDEFINED");
            }
            //console.log("3 - Unit data fetched:", unit);
            setCurrentUnit(unit);
            return unit;
        }

        setCurrentUnit(undefined);
        return undefined;
    };

    const getLocationData = async (location_id) => {
        if (!userData) return undefined;
        
        var locationRef;

        locationRef = query(
            collectionGroup(db, 'locations'),
            where('id', '==', location_id)
        );

        console.log("Firebase call 1")
        const querySnapshot = await getDocs(locationRef);

        if (!querySnapshot.empty && querySnapshot.docs[0]?.exists()) {
            const locationData = querySnapshot.docs[0].data();
            setCurrentLocation(locationData);
            return locationData;
        }

        console.log("Location not found");
        setCurrentLocation(undefined);
        return undefined;
    };

    const updateLocations = async () => {
        //console.log("Updating locations...");
        if (!userData?.companies) return [];

        let temp_locations = [];

        let locationCompanies = userData.location_companies;

        for (let locationCompanyKey in locationCompanies) {
            const locationCompany = locationCompanies[locationCompanyKey];
            const companyId = locationCompany["company"];
            const locationComponent = locationCompany["location"];
            const locationRef = collection(db, `companies/${companyId}/locations`);
            const q = query(locationRef, where('id', '==', locationComponent));
            console.log("Firebase call")
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                if (doc.exists()) {
                    temp_locations.push(doc.data());
                }
            });
        }

        //console.log("Firebase - ")
        for (let company of userData.companies) {
            const path = `companies/${company}/locations`;
            
            console.log("Firebase call")
            const querySnapshot = await getDocs(collection(db, path));

            querySnapshot.forEach(doc => {
                let data = doc.data();
                data['id'] = doc.id;
                temp_locations.push(data);
            });
        }

        //console.log("Setting from updateLocation: ", temp_locations);
        setAvailableLocations(temp_locations);
        return temp_locations;
    };

    useEffect(() => {
        if (currentUnit && currentUnit.firstUse) {
            const date = new Date(currentUnit.firstUse);
            setFirstUse(date);
        }
    }, [currentUnit]);

    return (
        <SecondHeaderContext.Provider value={{
            availableLocations,
            currentLocation,
            currentUnit,
            setCurrentUnit,
            resetCurrentUnit,
            resetCurrentLocation,
            latestUseString,
            getUnitsData,
            getUnitData,
            getLocationData,
            updateLocations,
            firstUse,

            setLatestUseString
        }}>
            {children}
        </SecondHeaderContext.Provider>
    );
};
